<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        mdi-file-eye
      </v-icon>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
        elevation="0"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Preview {{ domain }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="dialog = false"
          >
            {{$t('common.close', locale)}}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-layout
        wrap
        v-if="dialog"
      >
        <v-flex xs2 style="padding: 5px;" class="dtouchMobilePreview">
          <preview
            :url="`https://${domain}.dtouch.es/#/?fm=true`"
            :locale="locale"
            :size="{
              w: 375,
              h: 799,
            }"
            className="dtouchMobilePreview"
          />
        </v-flex>
        <v-flex xs3 style="padding: 5px;" class="dtouchTotemPreview">
          <preview
            :url="`https://${domain}.dtouch.es/#/totem?fullscreen=true`"
            :locale="locale"
            :size="{
              w: 1080,
              h: 1920,
            }"
            className="dtouchTotemPreview"
          />
        </v-flex>
        <v-flex xs7 style="padding: 5px;" class="dtouchDesktopPreview">
          <preview
            :url="`https://${domain}.dtouch.es/#/`"
            :locale="locale"
            :size="{
              w: 1920,
              h: 1080,
            }"
            className="dtouchDesktopPreview"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
//import api from '@/services/api'
import Preview from './Preview'
export default {
  components: {
    Preview,
  },
  props: {
    domain: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: true, 
      dialog: false,
    }
  },
  watch: {
  },
  computed: {
  },
  mounted () {
  },
  methods: {
  },
}
</script>
