<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="tableData"
      class="elevation-0 custom-table"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:[`item.identUser`]="{ item }">
        <a v-if="onEditItem" @click="onEditItem(item.ID)">{{ item.Name }}</a>
        <span v-else>{{ item.Name }}</span>
        <br />
        <span>{{ item.Email }}</span>
      </template>
      <template v-slot:[`item.ident`]="{ item }">
        <a v-if="onEditItem" @click="onEditItem(item.ID)">{{ item.Name }}</a>
        <span v-else>{{ item.Name }}</span>
      </template>
      <template v-slot:[`item.clone`]="{ item }">
        <v-icon
          v-if="onCloneItem"
          small
          class="mr-2"
          @click="onCloneItem(item.ID)"
        >
          mdi-content-copy
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="onCloneItem"
          small
          class="mr-2"
          @click="onCloneItem(item.ID)"
        >
          mdi-content-copy
        </v-icon>
        <v-icon
          v-if="onEditItem"
          small
          class="mr-2"
          @click="onEditItem(item.ID)"
        >
          mdi-pencil
        </v-icon>
        <delete-item
          v-if="onDeleteItem"
          :id="item.ID"
          :onDeleteItem="onDeleteItem"
          icon="mdi-delete"
          :locale="locale"
        />
      </template>
      <template v-slot:top>
        <custom-table-title 
          :onAdd="onEditItem"
          :title="title"
          :icon="icon"
          :onSearch="onSearch"
        />
      </template>
      <template v-slot:[`item.URLLink`]="{ item }">
        <a :href="item.URLLink">{{item.URLLink}}</a>
      </template>
      <template v-slot:[`item.users`]="{ item }">
        <template-users :ID="item.ID" />
      </template>
      <template v-slot:[`item.preview`]="{ item }">
        <template-dtouch-preview :domain="item.Domain" :locale="locale" />
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-icon
          v-if="!item.image"
          color="primary"
          x-large
          style="padding: 20px 0;"
        >
          mdi-account-circle
        </v-icon>
        <v-avatar
          v-else 
          size="36"
          style="margin: 20px 0;"
        >
          <img
            :src="item.image"
            :alt="item.Name"
          >
        </v-avatar>
      </template>
      <template v-slot:no-data>        
        <v-alert
          v-show="!loading"
          border="bottom"
          color="secondary"
          dark
          style="margin-top:20px"
        >
          {{ $t('common.messages.noData', locale) }}
          <v-btn
            v-if="onEditItem"
            @click="onEditItem(null)"
            style="margin-left: 20px;"
          >
          {{ $t('common.newItem', locale) }}
          </v-btn>
        </v-alert>
      </template>
    </v-data-table>
    <pagination
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
      :onGetData="onGetData"
      :locale="locale"
    />
  </v-card>
</template>
<script>
  import { mapState } from 'vuex'
  import CustomTableTitle from './CustomTableTitle'
  import TemplateUsers from './templates/TemplateUsers'
  import TemplateDtouchPreview from './templates/templateDtouchPreview/Index'
  import DeleteItem from '../DeleteItem'
  import Pagination from './Pagination'
  export default {
    components: {
      CustomTableTitle,
      TemplateUsers,
      TemplateDtouchPreview,
      Pagination,
      DeleteItem,
    },
    props: {
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      icon: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: null
      },
      headers: {
        type: Array,
        required: true
      },
      tableData: {
        type: Array,
        required: true
      },
      startIndex: {
        type: Number,
        required: false,
        default: null
      },
      count: {
        type: Number,
        required: false,
        default: null
      },
      itemsPerPage: {
        type: Number,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: true,
        default: null
      },
      onEditItem: {
        type: Function,
        required: false,
        default: null
      },
      onCloneItem: {
        type: Function,
        default: null,
      },
      onDeleteItem: {
        type: Function,
        required: false,
        default: null
      },
      onSearch: {
        type: Function,
        default: null
      },
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),
    computed: {
      ...mapState('app',['locale']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      },

      editItem (item) {
        this.editedIndex = this.tableData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.tableData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.tableData.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.tableData[this.editedIndex], this.editedItem)
        } else {
          this.tableData.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
<style>
  .v-data-table.custom-table th span {
    font-weight: 500 !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    letter-spacing: .009375em !important;
    line-height: 1.75rem;
  }
</style>
